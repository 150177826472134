<script lang="ts">
  import cx from "classnames"
  import Popover from "src/partials/Popover.svelte"
  import Anchor from "src/partials/Anchor.svelte"
  import {deriveFollowing, derivePerson, displayPerson, session, getWotScore} from "src/engine"

  export let pubkey

  const person = derivePerson(pubkey)
  const following = deriveFollowing(pubkey)
  const wotScore = getWotScore($session?.pubkey, pubkey)
</script>

<div class={cx("flex gap-1", $$props.class)}>
  <span class="cy-person-name overflow-hidden text-ellipsis">
    {displayPerson($person)}
  </span>
</div>
