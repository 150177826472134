<script lang="ts">
  import {randomId} from "hurdak"
  import {toggleTheme, appName} from "src/partials/state"
  import MenuItem from "src/partials/MenuItem.svelte"
  import FlexColumn from "src/partials/FlexColumn.svelte"
  import Anchor from "src/partials/Anchor.svelte"
  import PersonCircle from "src/app/shared/PersonCircle.svelte"
  import PersonHandle from "src/app/shared/PersonHandle.svelte"
  import MenuDesktopItem from "src/app/MenuDesktopItem.svelte"
  import MenuDesktopSecondary from "src/app/MenuDesktopSecondary.svelte"
  import {slowConnections} from "src/app/state"
  import {router} from "src/app/router"
  import { writable, get } from 'svelte/store';
  import {
    env,
    user,
    pubkey,
    canSign,
    hasNewMessages,
    hasNewNotifications,
    sessions,
    displayPerson,
    displayPubkey,
  } from "src/engine"
  import Feed from "src/app/shared/Feed.svelte";
  import {dereferenceNote, getGroupReqInfo} from "src/engine";
  import {Naddr, getIdOrAddress, noteKinds} from "src/util/nostr";
  import {Tags} from "paravel";
  import {mapstrpublickey} from "/constants.js";
  import Geohash from 'latlon-geohash';
  import Geolocation from '@react-native-community/geolocation';
 
  const {page} = router;
  let LocalView = false
  const closeSubMenu = () => {
    subMenu = null
  }

  var options = {};

  let LocalGeoHash = null
  function success(info) {
    LocalGeoHash = Geohash.encode(info.coords.latitude, info.coords.longitude, 4);
  };

  function error(err) {};

  let promise = Geolocation.getCurrentPosition(success, error, options);

  // can you make this one toggle class a la 
  // export const toggleTheme = () => theme.update(t => (t === "dark" ? "light" : "dark"))

  function ShowLocal() {
    LocalView = true
    let LocalButton = document.getElementById('LocalButton')
    LocalButton.classList.add('FeedButtonActive')
    let GlobalButton = document.getElementById('GlobalButton')
    GlobalButton.classList.remove('FeedButtonActive')
  }
 
  function ShowGlobal() {
    LocalView = false
    let GlobalButton = document.getElementById('GlobalButton')
    GlobalButton.classList.add('FeedButtonActive')
    let LocalButton = document.getElementById('LocalButton')
    LocalButton.classList.remove('FeedButtonActive')
  }

  const setSubMenu = name => {
    setTimeout(
      () => {
        subMenu = name
      },
      subMenu ? 100 : 0,
    )
  }

  const goToFeed = () => router.at("notes").push({key: randomId()})

  let subMenu, active

  $: {
    if ($page?.path.startsWith("/notes")) {
      active = "feed"
    } else if ($page?.path.startsWith("/notifications")) {
      active = "notifications"
    } else if ($page?.path.startsWith("/channels")) {
      active = "channels"
    }
  }

  const groupAddr = Tags.from(event).circles().first()
</script>

<div class="fixed bottom-0 left-0 top-0 w-24 z-[999] bg-cocoa transition-colors">
  <Anchor
    class="mb-4 flex mt-4 items-center gap-2 pl-6"
    external
    href="https://info.coracle.social">
    <!-- <img alt="App Logo" src={import.meta.env.VITE_LOGO_URL || "/images/logo.png"} class="w-12" /> -->
  </Anchor>
  <MenuDesktopItem path="/notes" on:click={goToFeed}>
    <i class="fa-solid fa-rss"></i>
  </MenuDesktopItem>
  {#if !$env.FORCE_GROUP}
    <MenuDesktopItem path="/settings/relays">
      <div class="relative inline-block">
        <i class="fa-solid fa-server"></i>
        {#if $slowConnections.length > 0}
          <div
            class="absolute -right-2.5 top-1 h-1.5 w-1.5 rounded bg-accent" />
        {/if}
      </div>
    </MenuDesktopItem>
  {/if}
  <!-- <MenuDesktopItem path="/notifications">
    <div class="relative inline-block">
      <i class="fa-solid fa-bell"></i>
      {#if $hasNewNotifications}
        <div
          class="absolute -right-2.5 top-1 h-1.5 w-1.5 rounded bg-accent" />
      {/if}
    </div>
  </MenuDesktopItem>
  <MenuDesktopItem path="/channels" disabled={!$canSign}>
    <div class="relative inline-block">
      <i class="fa-solid fa-envelope"></i>
      {#if $hasNewMessages}
        <div
          class="absolute -right-2.5 top-1 h-1.5 w-1.5 rounded bg-accent" />
      {/if}
    </div>
  </MenuDesktopItem> -->
  <FlexColumn small class="absolute bottom-0 w-72 z-[10000]">
    <!-- <Anchor class="Lato px-8 text-mid dark:text-cocoa-l hover:text-lighter dark:hover:text-warm" href="/about">About</Anchor> -->
    <Anchor
      class="Lato px-7 text-mid dark:text-cocoa-l hover:text-lighter dark:hover:text-warm"
      on:click={() => setSubMenu("settings")}>
      <i class="fa-solid fa-gear"></i>    
    </Anchor>
    <!-- <div class="Lato block flex h-8 gap-2 px-8 text-mid dark:text-cocoa-l">
      <Anchor external class="hover:text-lighter dark:hover:text-warm" href="/terms.html">Terms</Anchor> /
      <Anchor external class="hover:text-lighter dark:hover:text-warm" href="/privacy.html">Privacy</Anchor>
    </div> -->
    {#if subMenu === "settings"}
      <MenuDesktopSecondary onEscape={closeSubMenu}>
        <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" on:click={toggleTheme}>
          <i class="fa fa-palette" /> Toggle Theme
        </MenuItem>
        
        <!-- <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" href="/settings/data">
          <i class="fa fa-database" /> Database
        </MenuItem> -->
        
        <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" href="/settings" disabled={!$canSign}>
          <i class="fa fa-cog" /> App Settings
        </MenuItem>
        
        <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" href="/settings/content" disabled={!$canSign}>
          <i class="fa fa-volume-xmark" /> Content Settings
        </MenuItem>

      </MenuDesktopSecondary>
    {:else if subMenu === "account"}
      <MenuDesktopSecondary onEscape={closeSubMenu}>
        <MenuItem
          class="Lato flex items-center gap-4 py-4 pl-8"
          href={router.at("people").of($pubkey).toString()}>
          <i class="fa fa-user-circle" /> Profile
        </MenuItem>
        <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" href="/settings/keys">
          <i class="fa fa-key" /> Keys
        </MenuItem>
        <MenuItem
          class="Lato flex items-center gap-4 py-4 pl-8"
          on:click={() => setSubMenu("accounts")}>
          <i class="fa fa-right-left" /> Switch Account
        </MenuItem>
        <MenuItem class="Lato flex items-center gap-4 py-4 pl-8" href="/logout">
          <i class="fa fa-right-to-bracket" /> Log Out
        </MenuItem>
      </MenuDesktopSecondary>
    {:else if subMenu === "accounts"}
      <MenuDesktopSecondary onEscape={closeSubMenu}>
        {#each Object.values($sessions) as s (s.pubkey)}
          {#if s.pubkey !== $pubkey}
            <MenuItem class="py-4" on:click={() => pubkey.set(s.pubkey)}>
              <div class="flex items-center justify-center gap-2">
                <PersonCircle class="h-8 w-8 border border-solid border-warm" pubkey={s.pubkey} />
                {displayPubkey(s.pubkey)}
              </div>
            </MenuItem>
          {/if}
        {/each}
        <MenuItem
          class="Lato flex items-center justify-center gap-4 py-4"
          on:click={() => router.at("login/advanced").open()}>
          <i class="fa fa-plus" /> Add Account
        </MenuItem>
      </MenuDesktopSecondary>
    {/if}
    <div class="cursor-pointer px-5 pb-4 pt-3">
      {#if $pubkey}
        <Anchor class="flex items-center gap-2" on:click={() => setSubMenu("account")}>
          <PersonCircle class="h-10 w-10" pubkey={$pubkey} />
            <!-- <div class="flex min-w-0 flex-col">
              <span>@{displayPerson($user)}</span>
              <PersonHandle class="text-sm" pubkey={$pubkey} />
            </div> --> 
        </Anchor>
      {:else}
        <Anchor modal accent href="/login/intro">
          <i class="fa-regular fa-user"></i>
        </Anchor>
      {/if}
    </div>
  </FlexColumn>

  <div class="fixed bottom-0 left-24 top-0 w-72 z-[999] transition-colors overflow-y-auto">
    <div class="flex">
      <button on:click={ShowGlobal} class="grow h-11 FeedButton FeedButtonActive" id="GlobalButton">Global</button>
      <button on:click={ShowLocal} class="grow h-11 FeedButton" id="LocalButton">Local</button>
    </div>

    {#if LocalView }

      {#await promise}
        <p>Fetching local results</p>
      {:then number}
        <Feed
          hideControls
          shouldListen="true"
          anchor={getIdOrAddress(event)}
          relays={groupAddr ? getGroupReqInfo(groupAddr).relays : []}
          filter={{ 
            kinds: noteKinds, 
            "#r": [mapstrpublickey], 
            "#g": [LocalGeoHash]
          }} 
        />
      {:catch error}
        <p style="color: red">{error.message}</p>
      {/await}

    {:else}

      <Feed
        hideControls
        shouldListen="true"
        anchor={getIdOrAddress(event)}
        relays={groupAddr ? getGroupReqInfo(groupAddr).relays : []}
        filter={{kinds: noteKinds, "#r": [mapstrpublickey]}} 
      />

    {/if}
    
  </div>

</div>
 