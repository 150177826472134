<script lang="ts">
  import {onMount} from "svelte"
  import {Storage} from "hurdak"
  import {FeedLoader} from "src/engine"
  import {createScroller} from "src/util/misc"
  import {fly} from "src/util/transition"
  import {getModal} from "src/partials/state"
  import Spinner from "src/partials/Spinner.svelte"
  import FeedControls from "src/app/shared/FeedControls.svelte"
  import Note from "src/app/shared/Note.svelte"
  import OSMNote from "src/app/shared/Note.svelte"
  import type {DynamicFilter} from "src/engine"
  import { alert } from 'src/NostrEvents.js'
  import {
    readable,
    writable,
    compileFilters,
    searchableRelays,
    getRelaysFromFilters,
  } from "src/engine"
  import { getOSMEvents } from 'src/arcMapFunctionality.js'; 
  import Geolocation from '@react-native-community/geolocation';

  export let relays = []
  export let filter = {kinds: [1]}
  export let anchor = null
  export let shouldDisplay = null
  export let shouldListen = false
  export let hideControls = false
  export let hideSpinner = false
  export let showGroup = false
  export let onEvent = null
  export let allLocations = []

  let feed
  let alertContent = null
  let notes = writable([])
 
  const hideReplies = writable(Storage.getJson("hideReplies"))

  export const getRelays = () => {
    let selection = relays

    if (selection.length === 0 && filter.search) {
      selection = $searchableRelays
    }

    if (selection.length === 0) {
      selection = getRelaysFromFilters(compileFilters([filter]))
    }

    return selection
  }

  const loadMore = () => feed.load(5)

  const start = () => {
    feed?.stop()

    feed = new FeedLoader({
      filters: compileFilters([filter], {includeReposts: true}),
      relays: getRelays(),
      anchor,
      shouldListen,
      shouldDefer: true,
      shouldLoadParents: true,
      shouldHideReplies: $hideReplies,
      onEvent,
    })

    notes = feed.notes

    // let currentLat = null
    // let currentLng = null

    // function success(info) {

    //   currentLat = info.coords.latitude
    //   currentLng = info.coords.longitude
    //   // Todo: Radiae should be a stores as used in multiple places around the site now.
    //   let radiusOSM=3000;
    //   let radiusBTC=3000; 
    //   let osmLocations = getOSMEvents(radiusOSM, radiusBTC, currentLat, currentLng, null).then( (response) => {
    //     // Not sure how to merge these and have them work well with the rest of the code as they are not the same objects.
    //     let merged = {...feed.notes, ...response};
    //     notes.update((n) => merged); 
    //   });
    // };

    // function error(err) {};

    // Geolocation.getCurrentPosition(success, error, {});

    alert.subscribe((value) => alertContent = notes)
 
    if (shouldDisplay) {
      notes = notes.derived(xs => xs.filter(shouldDisplay))
    }
  } 

  const updateFilter = newFilter => {
    filter = newFilter

    start()
  }

  hideReplies.subscribe($hideReplies => {
    start()
    Storage.setJson("hideReplies", $hideReplies)
  })

  onMount(() => {
    start()

    const scroller = createScroller(loadMore, {element: getModal()})

    return () => {
      feed?.stop()
      scroller?.stop()
    }
  })
</script>

{#if !hideControls}
  <FeedControls {hideReplies} {filter} {relays} {updateFilter}>
    <slot name="controls" slot="controls" />
  </FeedControls>
{/if}

{#each $notes as note, i (note.id)}

  {#if note.type === "node"}
    <div in:fly={{y: 20}}>
      <OSMNote
        depth={$hideReplies ? 0 : 2}
        context={note.replies || []}
        filters={compileFilters([filter])}
        {showGroup}
        {anchor}
        {note}
      />
    </div>
  {:else}
    <div in:fly={{y: 20}}>
      <Note
        depth={$hideReplies ? 0 : 2}
        context={note.replies || []}
        filters={compileFilters([filter])}
        {showGroup}
        {anchor}
        {note}
      />
    </div>
  {/if}
  
{/each}
 
{#if !hideSpinner}
  <Spinner />
{/if}
