<script lang="ts">
  import Feed from "src/app/shared/Feed.svelte"
  import {dereferenceNote, getGroupReqInfo} from "src/engine"
  import {Naddr, getIdOrAddress, noteKinds} from "src/util/nostr"
  import {Tags} from "paravel"
  import {mapstrpublickey} from "/constants.js"

  const groupAddr = Tags.from(event).circles().first()
  
</script>

<!-- <div class="fixed bottom-0 left-0 top-0 w-24 z-nav bg-cocoa transition-colors"> -->

  <div class="z-nav transition-colors overflow-y-auto">

    <Feed
      hideControls
      shouldListen="true"
      anchor={getIdOrAddress(event)}
      relays={groupAddr ? getGroupReqInfo(groupAddr).relays : []}
      filter={{kinds: noteKinds, "#r": [mapstrpublickey]}} 
    />
  </div>

<!-- </div> -->