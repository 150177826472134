<script lang="ts">
  import {onMount} from "svelte"
  import {defer} from "hurdak"
  import {isMobile} from "src/util/html"
  import {getIdOrAddress} from "src/util/nostr"
  import {fly} from "src/util/transition"
  import Spinner from "src/partials/Spinner.svelte"
  import Note from "src/app/shared/Note.svelte"
  import OSMNote from "src/app/shared/OSMNote.svelte"
  import type {Event,DynamicFilter} from "src/engine"
  import {dereferenceNote,compileFilters} from "src/engine"
  import {router} from "src/app/router"
  
  export let relays = []
  export let context = []
  export let depth = isMobile ? 2 : 5
  export let filter: DynamicFilter = {}
  export let anchor = null
  export let showGroup = false
  let promise: Promise<Event> = defer()
  const {page, modal, modals} = router
  onMount(() => {
    promise = dereferenceNote($$props)
  })

  // For some reason this can either be a modal or a page so depending on which we populate note (OSM or Nostr location data) from the context of either one.  

  let note = $modal.config === 'undefined' ? $page.config.context.context[0] : $modal.config.context.context[0]
</script>

{#if note.type === 'node'}

  <!-- Open Street Maps Location being presented to user -->
  <div in:fly={{y: 20}}>
    <OSMNote showGroup showLoading {note} {depth} {relays} {context} />
  </div>

{:else}

  <!-- Nostr Location being presented to user -->
  <div in:fly={{y: 20}}>
    <Note 
      showGroup 
      showLoading 
      {note} 
      {depth} 
      {relays} 
      {context}
    />
  </div>
  
{/if}
