import Geohash from 'latlon-geohash';
import { writable, get } from 'svelte/store';
import Geolocation from '@react-native-community/geolocation';
import {queryOverpass} from '@derhuerst/query-overpass';
import {FeedLoader, compileFilters, getRelaysFromFilters} from "src/engine"
import { alert } from 'src/NostrEvents.js';
import * as L from 'leaflet';
import {mapstrpublickey} from "/constants.js";
import {getUserRelayUrls} from 'src/engine/relays/utils.ts'
import {router} from "src/app/router"
import {reject, whereEq, uniqBy, prop} from "ramda"

let feed
export let anchor = null
export let shouldListen = false
export let onEvent = null
export let context = []
let ctx = uniqBy(prop("id"), context)
export const MapHome = writable();
export const ZoomLevel = writable(2);
export const gotCoords = writable(false);
export const Relays = writable([]);
export const ReviewContent = writable(null);

function osmIcon(value){

    // At some stage you can get all the amenities from $amenities
    // and make each one associated with an icon. You could do some now.
    // "bar|atm|pharmacy|market_place|parking|parking_space|bench|place_of_worship|restaurant|school|waste_basket|bicycle_parking|fast_food|cafe|fuel|shelter|recycling|toilets|bank|atm"

    let pinName = null
    if(value.tags.amenity === 'restaurant'){
        pinName = 'Restaurant'
    }else if(value.tags.amenity === 'place_of_worship'){
        pinName = 'Religion'
    }else if(value.tags.amenity === 'school'){
        pinName = 'Education'
    }else if(value.tags.amenity === 'fast_food'){
        pinName = 'Restaurant'
    }else if(value.tags.amenity === 'cafe'){
        pinName = 'Coffee'
    }else if(value.tags.amenity === 'shelter'){
        pinName = 'Hotels & lodging'
    }else{
        pinName = 'osmPin'
    }
    
    return L.divIcon({
        className: 'nostrPin',
        html: "<img class='osmPin' src='./images/pins/osm/"+pinName+".svg' />"
    });
}

function nostrIcon(value){

    let pinName = null
    value.tags.forEach(tag => { 
        if(tag[0] === "subject"){
            pinName = tag[1]
        }
    });

    if (pinName == null){

        return L.divIcon({
            className: 'nostrPin',
            html: "<img class='pinImage' src='./images/pins/nostr/nostrPin.svg' />"
        });
    }else{
        
        return L.divIcon({
            className: 'nostrPin',
            html: "<img class='pinImage' src='./images/pins/nostr/"+pinName+".svg' />"
        });
    }
    
}

function btcIcon(value){
    return L.divIcon({
      className: 'btcpin',
      html: "<img class='pinImage' src='./images/pins/btc.svg' />"
    });
    // <p style='background:#fff;padding:3px;width:max-content'>"+value.tags.name+"</p>
}

function syncMarkers(markersMap) {
    for (let [id, marker] of markersMap.entries()) {
      get(MapHome).addLayer(marker);
    }
}

function mergeArrays(...arrays) { 
    let mergedArray = []; 

    arrays.forEach(array => { 
        mergedArray = mergedArray.concat(array) 
    }); 

    return mergedArray; 
} 

export async function GetEvents(currentLat, currentLng, mapstrpublickey, ndk, bounds) {
    let geohash = Geohash.encode(currentLat, currentLng, 4);
	let radiusOSM=3000;
	let radiusBTC=3000; 
    let osm = await getOSMEvents(radiusOSM, radiusBTC, currentLat, currentLng, null)
    let nostr = await getNostEvents(ndk, mapstrpublickey, geohash)
    let points = [...osm, ...nostr];
    return points;
}
 
export async function getOSMEvents(radiusOSM, radiusBTC, currentLat, currentLng, bounds ){

    let listOfAmenities = "bar|atm|pharmacy|market_place|parking|parking_space|bench|place_of_worship|restaurant|school|waste_basket|bicycle_parking|fast_food|cafe|fuel|shelter|recycling|toilets|bank|atm"
    let overpassQuery = "[out:json];"+
                        "("+
                        "node(around:"+radiusOSM+","+currentLat+","+currentLng+")['name']['amenity'~'"+listOfAmenities+"'](newer:'2021-01-01T00:00:00Z');"+ 
                        "node['name']['currency:XBT'='yes'](around:"+radiusBTC+","+currentLat+","+currentLng+")(newer:'2021-01-01T00:00:00Z');"+
                        ");"+
                        "out meta;" 
    
    // get nodes by bounds - not working here but is in https://overpass-turbo.eu/
    // let overpassQuery = "[bbox:18.79874560354912, 98.97340133786203, 18.799022368502808, 98.97533252835275];(node['name']['amenity'~'bar|atm|pharmacy|market_place|parking|parking_space|bench|place_of_worship|restaurant|school|waste_basket|bicycle_parking|fast_food|cafe|fuel|shelter|recycling|toilets|bank|atm'];);out meta;"
    // test query if having issues at https://overpass-turbo.eu/
    // console.log(overpassQuery) 
    
    const api = await fetch('https://www.overpass-api.de/api/interpreter?', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
        body: overpassQuery
    })
    const answer = await api.json();
    return answer.elements
}

export async function getNostEvents(ndk, mapstrpublickey, geohash, mapRelatedOptions=true, osmId=null) {

    let opts = {}
    if(mapRelatedOptions){
        opts = {
            kinds: [1],
            '#r': [mapstrpublickey],
            '#g': [geohash]
        }
    }else{
        opts = {
            kinds: [1],
            '#r': [mapstrpublickey],
            '#r': [osmId+"_osmId"],
            "limit": 10
        }
    }

    let points = []
    let events = await ndk.fetchEvents(opts).then((response)=>{
        
        Array.from(response).map((event) => {

            let latLngString = '';
            let lat = 0;
            let lng = 0;
            let title = '';
            let contentString = '';
            
            event.tags.forEach(eventTagsArray => { 

                // a tag is where the coordinates are for each nostr event made with arcmapbtc
                if( eventTagsArray[0] == 'a' ){
                    
                    contentString = event.content.split('||');
                    latLngString = eventTagsArray[1].split(',');
                    lat = parseFloat(latLngString[0], 10);
                    lng = parseFloat(latLngString[1], 10);
                    title = event.tags[2][1];

                    if (
                        typeof lat === 'number' &&
                        typeof lng === 'number'
                    ) {

                        points.push({
                            type: 'nostr',
                            location: eventTagsArray[1],
                            content: contentString[0],
                            id: event.id,
                            npub: event.pubkey,
                            title: title,
                            dateCreated: event.created_at,
                            tags: event.tags
                        });
                    }
                }
            });
        });
    }) 

    return points;
}

export async function createMarkers(response, markers, mapObject){

    for (let [key, value] of Object.entries(response)) {
      let lat = null 
      let lng = null
      let Icon = null

      // value.lat means we are iterating through an OSM tag
      if(value.lat){
        lat = value.lat
        lng = value.lon
        if(value.tags['currency:XBT']){
          Icon = btcIcon(value)
        }else{
          Icon = osmIcon(value)
        }
      }else{
        Icon = nostrIcon(value)
        let splitCoords = value.location.split(',')
        lat = parseFloat(splitCoords[0], 10)
        lng = parseFloat(splitCoords[1], 10) 
      }

      let marker = L.marker(
        [lat,lng], 
        { icon: Icon }
      ).addTo(mapObject).on('click', markerClick);

      // if( value.location == "18.799079752903776,98.97117996203635" ){
        // console.log(value.location)
      // }
      // "18.799079752903776,98.97117996203635"

      marker.locationData = value

      function markerClick(e) {
        router.at("/")
          router
            .at("location")
            .cx({context: ctx.concat(value)})
            .open()
      }

      markers.set(key, marker);
      $: if(mapObject) syncMarkers(markers);
    }
}
