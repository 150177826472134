<script lang="ts">
  import {Tags, createEvent} from "paravel"
  import {createEventDispatcher} from "svelte"
  import {join, without, uniq} from "ramda"
  import {asNostrEvent} from "src/util/nostr"
  import {slide} from "src/util/transition"
  import ImageInput from "src/partials/ImageInput.svelte"
  import AlternatingBackground from "src/partials/AlternatingBackground.svelte"
  import Chip from "src/partials/Chip.svelte"
  import Compose from "src/app/shared/Compose.svelte"
  import NsecWarning from "src/app/shared/NsecWarning.svelte"
  import NoteOptions from "src/app/shared/NoteOptions.svelte"
  import NoteImages from "src/app/shared/NoteImages.svelte"
  import {getNostEvents} from 'src/arcMapFunctionality.js'
  import {mapstrpublickey} from '/constants.js'
  import OsmNoteContent from "src/app/shared/OsmNoteContent.svelte"
  import {
    env,
    Publisher,
    uniqTags,
    writable,
    publishToZeroOrMoreGroups,
    tagsFromContent,
    getClientTags,
    getReplyTags,
    session,
    getPublishHints,
    displayPubkey,
    mention,
    compileFilters,
    getRelaysFromFilters,
    pubkey
  } from "src/engine"
  import NDK from '@nostr-dev-kit/ndk';
  import {toastProgress} from "src/app/state"
  import type {DynamicFilter} from "src/engine"

  // not sure why you need to do this twice - cant export properly. Maybe needs to be store?
  export async function connectNDK(ndk) {
      return await ndk.connect();
  }

  const filter: DynamicFilter = {kinds: [1]}
  const filters = compileFilters([filter])
  const relays = getRelaysFromFilters(filters)
  export const ndk = new NDK({
      explicitRelayUrls: relays,
  });
  const showEntire = true;
  connectNDK(ndk);

  export let parent
  export let showBorder = false
  export let forceOpen = false 
  const dispatch = createEventDispatcher()
  const nsecWarning = writable(null)

  let images, compose, container, options
  let isOpen = true
  let mentions = []
  let draft = ""
  let opts = {
    warning: "",
    relays: getPublishHints(parent),
    anonymous: false,
  }

  export const start = () => {
    dispatch("start")

    isOpen = true
    mentions = without(
      [$session.pubkey]
    )

    setTimeout(() => compose.write(draft))
  }

  const bypassNsecWarning = () => {
    nsecWarning.set(null)
    send({skipNsecWarning: true})
  }

  const setOpts = e => {
    opts = {...opts, ...e.detail}
  }

  const saveDraft = () => {
    if (compose) {
      draft = compose.parse()
    }
  }

  const clearDraft = () => {
    draft = ""
  }

  const reset = () => {
    dispatch("reset")

    isOpen = false
    compose = null
    mentions = []
  }

  const removeMention = pubkey => {
    mentions = without([pubkey], mentions)
  }

  const send = async ({skipNsecWarning = false} = {}) => {
    
    let content = ""
    if(compose === null){
      content = document.getElementById('arcmapInputValue').innerHTML.trim()
      for (const imeta of images.getValue()) {
        content += " " + imeta.type("url").values().first().toString() + " || View at https://arcmapbtc.com/"
      }
    }else{
      content = compose.parse().trim()
    }

    if (!content) return

    if (!skipNsecWarning && content.match(/\bnsec1.+/)) return nsecWarning.set(true)
      
    const tags = uniqTags([
      ...mentions.map(mention),
      // ...getReplyTags(parent, true),
      ...tagsFromContent(content),
      ...getClientTags(),
      ['a', parent.lat.toString() + "," + parent.lon.toString()],
      ['r', mapstrpublickey],
      ['r', parent.id+"_osmId"],
      ['l', 'mapstrReviewEvent'],
    ])

    for (const imeta of images.getValue()) {
      tags.push(["imeta", ...imeta.all().map(join(" "))])
    }

    if (opts.warning) {
      tags.push(["content-warning", opts.warning])
    }

    const template = createEvent(1, {content, tags})

    const addresses = Tags.from(parent).circles().all()
    const {pubs} = await publishToZeroOrMoreGroups(addresses, template, opts) 
    pubs[0].on("progress", toastProgress)
    clearDraft()
    reset()
  } 

  const onBodyClick = e => {
    const target = e.target as HTMLElement

    if (isOpen && container && !container.contains(target)) {
      saveDraft()
      reset()
    }
  }
</script>

<svelte:body on:click={onBodyClick} />

  {#if $pubkey}
    <div
      transition:slide|local
      class="note-reply relative z-feature my-2 flex flex-col gap-1"
      bind:this={container}
      on:click|stopPropagation>
      {#if showBorder}
        <AlternatingBackground class="absolute bottom-0 left-4 top-0 z-none -my-2 w-px" />
      {/if}
      <AlternatingBackground class="z-feature overflow-hidden rounded">
        <div class="p-3 text-lightest" class:rounded-b={mentions.length === 0}>
          <Compose bind:this={compose} onSubmit={() => send()} style="min-height: 4rem">
            <div class="flex flex-col justify-start" slot="addon">
              <button
                on:click={() => send()}
                class="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full transition-all hover:bg-accent">
                <i class="fa fa-paper-plane" />
              </button>
            </div>
          </Compose>
        </div>
        <div class="p-2">
          <NoteImages bind:this={images} bind:compose includeInContent />
        </div>
        <div class="h-px" />
        <div class="flex gap-2 rounded-b p-2 text-sm text-lightest">
          <div class="inline-block border-r border-solid border-mid py-2 pl-1 pr-3">
            <div class="flex cursor-pointer items-center gap-3">
              <ImageInput multi hostLimit={3} on:change={e => images.addImage(e.detail)}>
                <i slot="button" class="fa fa-paperclip" />
              </ImageInput>
              {#if !$env.FORCE_GROUP}
                <i class="fa fa-cog" on:click={() => options.setView("settings")} />
              {/if}
              <i class="fa fa-at" />
            </div>
          </div>
          <div on:click|stopPropagation>
            {#each mentions as pubkey}
              <Chip class="mb-1 mr-1" theme="dark" onRemove={() => removeMention(pubkey)}>
                {displayPubkey(pubkey)}
              </Chip>
            {:else}
              <div class="text-lightest inline-block py-2">No mentions</div>
            {/each}
            <div class="-mb-2" />
          </div>
        </div>
      </AlternatingBackground>

      <!-- comments here -->
      {#await getNostEvents(ndk, mapstrpublickey, null, false, parent.id)}
        <p>Fetching reviews for this location...</p>
      {:then events}
        <ul>
          {#each events as event, index}
            <OsmNoteContent note={event} {showEntire} />
          {/each}
        </ul>
      {:catch error}
        <p>Error fetching reviews for this location</p>
      {/await}

      {#if $nsecWarning}
        <NsecWarning onAbort={() => nsecWarning.set(null)} onBypass={bypassNsecWarning} />
      {/if}

    </div>
  {:else}
    <p class="text-center">
      <b>Login to create a review</b>
    </p>

    <!-- comments here -->
    {#await getNostEvents(ndk, mapstrpublickey, null, false, parent.id)}
      <p>Fetching reviews for this location...</p>
    {:then events}

      <ul>
        {#each events as event, index}
          <OsmNoteContent note={event} {showEntire} />
        {/each}
      </ul>
      
    {:catch error}
      <p>Error fetching reviews for this location</p>
    {/await}
  {/if}
