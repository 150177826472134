<script lang="ts">
  import { getContext } from 'svelte';
  import Feeds from "src/app/views/Feeds.svelte";
  import GroupDetail from "src/app/views/GroupDetail.svelte";
  import type {DynamicFilter} from "src/engine";
  import { writable, get } from 'svelte/store';
  import {
    env, 
    pubkey,
    readable,
    compileFilters,
    searchableRelays,
    getRelaysFromFilters,
    getEventHints
  } from "src/engine"
  import * as L from 'leaflet';
  import {provider} from 'leaflet-providers';
  import {FeedLoader} from "src/engine";
  import {mapstrpublickey} from "/constants.js";
  import {Naddr, getIdOrAddress, noteKinds} from "src/util/nostr";
  import Geolocation from '@react-native-community/geolocation';
  import {GetEvents, ZoomLevel, MapHome, gotCoords, createMarkers} from 'src/arcMapFunctionality.js';
  import NDK from '@nostr-dev-kit/ndk';
  import {router} from "src/app/router";
  import {reject, whereEq, uniqBy, prop} from "ramda";
  import * as geocoder from 'src/Control.Geocoder.js';

  // current location coords
  let pyramidCoords = [29.97914408198538, 31.134186895230926]
  export const currentCoords = writable(pyramidCoords, () => {

    var options = {};
    function success(info) {
      
      currentCoords.update((n) => [ info.coords.latitude, info.coords.longitude ]);
      get(MapHome).setView([ info.coords.latitude, info.coords.longitude ], 18);
      let markers = new Map();
      GetEvents(info.coords.latitude, info.coords.longitude, mapstrpublickey, ndk, get(MapHome).getBounds()).then((response, i) => {
        createMarkers(response, markers, get(MapHome))
      })
    };

    function error(err) {};
    Geolocation.getCurrentPosition(success, error, options);
  });

  export let note
  export let context = []
  let ctx = uniqBy(prop("id"), context)
  let map;
  let event = note;
  let zoom = get(ZoomLevel);

  export async function connectNDK(ndk) {
      return await ndk.connect();
  }

  const filter: DynamicFilter = {kinds: [1]}
  const filters = compileFilters([filter])
  const relays = getRelaysFromFilters(filters)
  export const ndk = new NDK({
      explicitRelayUrls: relays,
  });

  connectNDK(ndk);
  
  function resizeMap() {
    if(map) { map.invalidateSize(); }
  }

  export function createMap(container, notes) {

    let m = L.map(container, { zoomControl: false }).setView(get(currentCoords), 18);
    MapHome.update((n) => m); 
    
    L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      {
        attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
          &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`
      }
    ).addTo(m);

    // L.tileLayer.provider('Stadia.OSMBright').addTo(m);

    m.on('contextmenu', (e)=>{
      createLocation(e)
    });

    var geocoder = L.Control.geocoder({
      showUniqueResult: true,
      showResultIcons: true,
      collapsed: false,
      expand: 'touch',
      position: 'topleft',
      placeholder: 'Search location',
      errorMessage: 'Nothing found.',
      iconLabel: 'Initiate a new search',
      query: '',
      queryMinLength: 1,
      suggestMinLength: 3,
      suggestTimeout: 250,
      defaultMarkGeocode: false
    }).on('markgeocode', function(e) {
      map.setView([e.geocode.center.lat,e.geocode.center.lng], 18);
      let markers = new Map();
      GetEvents(e.geocode.center.lat, e.geocode.center.lng, mapstrpublickey, ndk, m.getBounds()).then((response, i) => {
                    createMarkers(response, markers, get(MapHome))
              })
    }).addTo(m);

    // add current location button
    L.Control.Watermark = L.Control.extend({
        onAdd: function(map) {

            var img = L.DomUtil.create('img');
            img.src = './images/goToLocation.svg';
            img.style.width = '35px';

            img.addEventListener("click", (event) => {
              
              let coords = get(currentCoords)
              m.setView(coords, get(ZoomLevel));
              GetEvents(coords[0], coords[1], mapstrpublickey, ndk, m.getBounds()).then((response, i) => {
                    createMarkers(response, markers, get(MapHome))
              })
            });
            return img;
        },

        onRemove: function(map) {}
    });

    L.control.watermark = function(opts) {
        return new L.Control.Watermark(opts);
    }

    L.control.watermark({ position: 'bottomright' }).addTo(m);

    return m;
  }

  function mapAction(container) {
    map = createMap(container);
    return {
      destroy: () => {
        map.remove();
      },
    };
  }

  const {page} = router

  function createLocation(e) {
    if (!$pubkey) {
      return router.at("/login/intro").open()
    }
    router.at("notes/create?coords="+e.latlng.lat+","+e.latlng.lng).open()
  }

</script>

<div class="w-screen sm:w-screen lg:w-mapDesktopWidth h-screen z-[1000]" use:mapAction on:resize={resizeMap} />
