<script lang="ts">
  import {onMount} from "svelte"
  import {nip19} from "nostr-tools"
  import {v4 as uuid} from "uuid"
  import {join, whereEq, identity} from "ramda"
  import {throttle, commaFormat, toTitle, switcherFn} from "hurdak"
  import {createEvent, now, Tags} from "paravel"
  import {asNostrEvent} from "src/util/nostr"
  import {currencyOptions} from "src/util/i18n"
  import {dateToSeconds} from "src/util/misc"
  import {toast} from "src/partials/state"
  import Anchor from "src/partials/Anchor.svelte"
  import Compose from "src/app/shared/Compose.svelte"
  import ImageInput from "src/partials/ImageInput.svelte"
  import CurrencyInput from "src/partials/CurrencyInput.svelte"
  import CurrencySymbol from "src/partials/CurrencySymbol.svelte"
  import DateTimeInput from "src/partials/DateTimeInput.svelte"
  import Field from "src/partials/Field.svelte"
  import Input from "src/partials/Input.svelte"
  import Content from "src/partials/Content.svelte"
  import Popover from "src/partials/Popover.svelte"
  import Menu from "src/partials/Menu.svelte"
  import MenuItem from "src/partials/MenuItem.svelte"
  import Chip from "src/partials/Chip.svelte"
  import NsecWarning from "src/app/shared/NsecWarning.svelte"
  import NoteContent from "src/app/shared/NoteContent.svelte"
  import NoteOptions from "src/app/shared/NoteOptions.svelte"
  import NoteImages from "src/app/shared/NoteImages.svelte"
  import {Publisher, mention} from "src/engine"
  import {toastProgress} from "src/app/state"
  import {router} from "src/app/router"
  import {
    env,
    session,
    writable,
    getEventHints,
    getClientTags,
    tagsFromContent,
    publishToZeroOrMoreGroups,
    getGroupPublishHints,
  } from "src/engine"
  import {getProps} from "src/util/router"
  import {mapstrpublickey} from "/constants.js"
  import Geohash from 'latlon-geohash';

  export let type = "note"
  export let quote = null
  export let pubkey = null
  export let group = null
  export let initialValues = {}

  const defaultGroups = quote ? Tags.from(quote).circles().all() : [group].filter(identity)

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const selectedCoords = urlParams.get('coords')
  const individualCoords = selectedCoords.split(',')

  let images, compose
  let charCount = 0
  let wordCount = 0
  let showPreview = false
  let options
  let opts = {
    title: "",
    warning: "",
    summary: "",
    price: "",
    currency: currencyOptions.find(whereEq({code: "SAT"})),
    relays: getGroupPublishHints(defaultGroups),
    groups: defaultGroups,
    anonymous: false,
    location: null,
    start: null,
    end: null,
    selectedCoords: selectedCoords,
    subject: "",
    geoHash: Geohash.encode(individualCoords[0], individualCoords[1], 4),
    ...initialValues,
    acceptsOnChainBtc: null,
    acceptsBtcViaLn: null
  }

  const nsecWarning = writable(null)

  const setOpts = e => {
    opts = {...opts, ...e.detail}
  }

  const bypassNsecWarning = () => {
    nsecWarning.set(null)
    onSubmit({skipNsecWarning: true})
  }

  const onSubmit = async ({skipNsecWarning = false} = {}) => {
    
    let content = ""
    if(compose === null){
      content = document.getElementById('arcmapInputValue').innerHTML.trim()
    }else{
      content = compose.parse().trim().concat(" || View at https://arcmapbtc.com/");
    }
    
    // const content = compose.parse().trim().concat(" || Create your own at https://arcmapbtc.com/");
    if (!content) return toast.show("error", "Please provide a description.")

    if (!skipNsecWarning && content.match(/\bnsec1.+/)) return nsecWarning.set(true)

    if (type === "calendar_event") {
      if (!opts.title) {
        return toast.show("error", "Please name your event.")
      }

      if (!opts.start || !opts.end) {
        return toast.show("error", "Please provide a start and end date and time.")
      }
    }

    if (type === "listing") {
      if (!opts.title) {
        return toast.show("error", "Please name your listing.")
      }

      if (isNaN(parseFloat(opts.price))) {
        return toast.show("error", "Please provide a valid price.")
      }

      if (!opts.currency) {
        return toast.show("error", "Please select a currency.")
      }
    }

    const tags = [...tagsFromContent(content), ...getClientTags()]

    for (const imeta of images.getValue()) {
      if (type === "listing") {
        tags.push(["image", imeta.type("url").values().first()])
      } else {
        tags.push(["imeta", ...imeta.all().map(join(" "))])
      }
    }

    if (opts.warning) {
      tags.push(["content-warning", opts.warning])
    }

    if (quote) {
      tags.push(mention(quote.pubkey))

      // Re-broadcast the note we're quoting
      if (!opts.groups.length) {
        Publisher.publish({
          relays: opts.relays,
          event: asNostrEvent(quote),
        })
      }
    }

    const template = switcherFn(type, {
      note: () => createEvent(1, {
        content, 
        tags:[
          ...tags,
          ['r', mapstrpublickey],
          ['a', opts.selectedCoords],
          ['g', opts.geoHash],
          ['title', opts.title],
          ['subject', opts.subject],
          ['t', 'mapstr'],
          ['l', 'mapstrLocationEvent'],
          ['r', "acceptsOnChainBtc_"+opts.acceptsOnChainBtc],
          ['r', "acceptsBtcViaLn_"+opts.acceptsBtcViaLn],
        ]
      }),
      
      // listing: () =>
      //   createEvent(30402, {
      //     content,
      //     tags: [
      //       ...tags,
      //       ["d", uuid()],
      //       ["title", opts.title],
      //       ["summary", opts.summary || ""],
      //       ["location", opts.location || ""],
      //       ["published_at", now().toString()],
      //       ["price", opts.price, opts.currency.code],
      //     ],
      //   }),
      // calendar_event: () =>
      //   createEvent(31923, {
      //     content,
      //     tags: [
      //       ...tags,
      //       ["d", uuid()],
      //       ["name", opts.title],
      //       ["start", dateToSeconds(opts.start).toString()],
      //       ["end", dateToSeconds(opts.end).toString()],
      //       ["location", opts.location || ""],
      //     ],
      //   }),

    })

    const {pubs} = await publishToZeroOrMoreGroups(opts.groups, template, opts)

    pubs[0].on("progress", toastProgress)

    router.clearModals()
  }

  const togglePreview = () => {
    showPreview = !showPreview
  }

  const updateCounts = throttle(300, () => {
    if (compose) {
      const content = compose.parse()

      charCount = content.length || 0
      wordCount = content.trim() ? (content.match(/\s+/g)?.length || 0) + 1 : 0
    }
  })

  const setType = t => {
    type = t
  }

  onMount(() => {
    if (pubkey && pubkey !== $session.pubkey) {
      compose.mention(pubkey)
    }

    if (quote) {
      const nevent = nip19.neventEncode({id: quote.id, relays: getEventHints(quote)})

      compose.nevent("nostr:" + nevent)
    }
  })
</script>

<form on:submit|preventDefault={() => onSubmit()}>
  <Content size="lg">
    <div class="flex gap-2">
      <span class="text-2xl font-bold">Create New Location</span>
    </div>
    <div class="flex w-full flex-col gap-4">
      <Field label="Title">
        <Input bind:value={opts.title} />
      </Field>
      <Field label="Subject">
        <select class="rounded shadow-inset py-2 px-4 w-full placeholder:text-lighter bg-white border border-solid border-lighter text-black" bind:value={opts.subject}>
          <option value="" label="Select a primary use">
              Select a primary use for this location
          </option>
          <option value="Coffee" label="Coffee">
              Coffee
          </option>
          <option value="Restaurant" label="Restaurant">
              Restaurant
          </option>
          <option value="Bar" label="Bar">
              Bar
          </option>
          <option value="Shopping" label="Shopping">
              Shopping
          </option>
          <option value="Services" label="Services">
              Services
          </option>
          <option value="Hotels & lodging" label="Hotels & lodging">
              Hotels & lodging
          </option>
          <option value="Outdoors & recreation" label="Outdoors & recreation">
              Outdoors & recreation
          </option>
          <option value="Religion" label="Religion">
              Religion
          </option>
          <option value="Office & industrial" label="Office & industrial">
              Office & industrial
          </option>
          <option value="Residential" label="Residential">
              Residential
          </option>
          <option value="Education" label="Education">
              Education
          </option>
        </select>
      </Field>

      <Field label="Accepts On Chain BTC:">
        <select class="rounded shadow-inset py-2 px-4 w-full placeholder:text-lighter bg-white border border-solid border-lighter text-black" bind:value={opts.acceptsOnChainBtc}>
          <option value="" label="Select an option">
              Select an option
          </option>
          <option value={true} label="Yes">
              Yes
          </option>
          <option value={false} label="No">
              No
          </option>
        </select>
      </Field>

      <Field label="Accepts BTC via LN:">
        <select class="rounded shadow-inset py-2 px-4 w-full placeholder:text-lighter bg-white border border-solid border-lighter text-black" bind:value={opts.acceptsBtcViaLn}>
          <option value="" label="Select an option">
              Select an option
          </option>
          <option value={true} label="Yes">
              Yes
          </option>
          <option value={false} label="No">
              No
          </option>
        </select>
      </Field>

      <Field label="Coordinates">
        <Input bind:value={opts.selectedCoords} />
      </Field>

      <Field label="Description including hastags">
        <div
          class="rounded-xl border border-solid border-mid p-3"
          class:bg-white={!showPreview}
          class:text-black={!showPreview}
          class:bg-cocoa={showPreview}>
          {#if showPreview}
            <NoteContent placeholder="test" note={{content: compose.parse(), tags: []}} />
          {/if}
          <div class:hidden={showPreview}>
            <Compose on:keyup={updateCounts} bind:this={compose} {onSubmit} />
          </div>
        </div>
        <div class="flex items-center justify-end gap-2 text-lighter">
          <small class="hidden sm:block">
            {commaFormat(charCount)} characters
          </small>
          <span>•</span>
          <small class="hidden sm:block">
            {commaFormat(wordCount)} words
          </small>
          <span>•</span>
          <small on:click={togglePreview} class="cursor-pointer underline">
            {showPreview ? "Hide" : "Show"} Preview
          </small>
        </div>
      </Field>
      <NoteImages bind:this={images} bind:compose includeInContent={type !== "listing"} />
      <div class="flex gap-2">
        <Anchor button tag="button" type="submit" class="flex-grow">Send</Anchor>
        <ImageInput
          multi
          hostLimit={3}
          circle={false}
          on:change={e => images?.addImage(e.detail)} />
      </div>
      {#if !$env.FORCE_GROUP}
        <small
          class="flex cursor-pointer items-center justify-end gap-4"
          on:click={() => options.setView("settings")}>
          <span class:text-accent={opts.groups.length > 0}>
            <i class="fa fa-circle-nodes" />
            {opts.groups.length}
          </span>
          <span><i class="fa fa-server" /> {opts.relays?.length}</span>
          <span><i class="fa fa-warning" /> {opts.warning || 0}</span>
        </small>
      {/if}
    </div>
  </Content>
</form>

<NoteOptions on:change={setOpts} bind:this={options} initialValues={opts} />

{#if $nsecWarning}
  <NsecWarning onAbort={() => nsecWarning.set(null)} onBypass={bypassNsecWarning} />
{/if}
