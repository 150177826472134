<script lang="ts">
  import cx from 'classnames'
  import Anchor from "src/partials/Anchor.svelte"
</script>

<Anchor
  {...$$props}
  class={cx($$props.class, "h-10 w-10 flex flex-col justify-center items-center gap-3 bg-cocoa")}
  on:click>
  <slot />
</Anchor>
