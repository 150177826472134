<script lang="ts">
  import cx from "classnames"
  import {matchFilters} from "paravel"
  import {reject, whereEq, uniqBy, prop} from "ramda"
  import {onMount, onDestroy} from "svelte"
  import {quantify, batch} from "hurdak"
  import {fly} from "src/util/transition"
  import {
    getIdOrAddress,
    isChildOf,
    isLike,
    getParentId,
    getRootId,
    getParentIds,
    getRootIds,
  } from "src/util/nostr"
  import {formatTimestamp} from "src/util/misc"
  import Popover from "src/partials/Popover.svelte"
  import AlternatingBackground from "src/partials/AlternatingBackground.svelte"
  import Spinner from "src/partials/Spinner.svelte"
  import Anchor from "src/partials/Anchor.svelte"
  import Card from "src/partials/Card.svelte"
  import NoteMeta from "src/app/shared/NoteMeta.svelte"
  import PersonCircle from "src/app/shared/PersonCircle.svelte"
  import PersonName from "src/app/shared/PersonName.svelte"
  import OSMNoteReply from "src/app/shared/OSMNoteReply.svelte"
  import NoteActions from "src/app/shared/NoteActions.svelte"
  import NoteContent from "src/app/shared/NoteContent.svelte"
  import {router} from "src/app/router"
  import {getProps, getKey} from "src/util/router"
  import {get} from 'svelte/store';
  import {MapHome} from 'src/arcMapFunctionality.js';
  import {
    env,
    load,
    nip59,
    people,
    loadOne,
    getLnUrl,
    getZapper,
    processZaps,
    getReplyHints,
    isEventMuted,
    getEventHints,
    getIdFilters,
    getReplyFilters,
    getSetting,
    getRecipientKey,
    selectHints,
    mergeHints,
    loadPubkeys,
    sortEventsDesc,
  } from "src/engine"

  export let note
  export let relays = []
  export let context = []
  export let filters = null
  export let depth = 0
  export let anchor = null
  export let topLevel = false
  export let isLastReply = false
  export let showParent = true
  export let showLoading = false
  export let showMuted = false
  export let showGroup = false

  let zapper, unsubZapper
  let ready = false
  let event = note
  let reply = null
  let replyIsActive = false
  let showMutedReplies = false
  let actions = null
  let collapsed = depth === 0
  let ctx = uniqBy(prop("id"), context)

  const {page, modal, modals} = router

  const onClick = e => {
    const target = (e.detail?.target || e.target) as HTMLElement

    if (interactive && !["I"].includes(target.tagName) && !target.closest("a")) {
      router
        .at("notes")
        .of(event.id, {relays: getEventHints(event)})
        .cx({context: ctx.concat(event)})
        .open()
    }
  }

  const showPerson = () => router.at("people").of(event.pubkey).open()

  const goToParent = () => {
    router
      .at("notes")
      .of(getParentId(event), {relays: tags.getReplyHints()})
      .cx({context: ctx.concat(event)})
      .open()
  }

  const goToThread = () =>
    router
      .at("notes")
      .of(event.id, {relays: getEventHints(event)})
      .at("thread")
      .cx({context: ctx.concat(event)})
      .open()

  const removeFromContext = e => {
    ctx = reject(whereEq({id: e.id}), ctx)
  }

  const addToContext = e => {
    ctx = ctx.concat(e)
  }

  onMount(async () => {
    ready = true
  })

  function goToLocation(){

    router.clearModals()
    let map = get(MapHome)
    let circles = L.layerGroup().addTo(map);

    if(event.lat){
      map.setView([event.lat, event.lon], 20)
      map.eachLayer((layer) => {
        if(layer._mRadius === 20){
          map.removeLayer(layer);
        }
      });
      let circle = L.circle([event.lat,event.lon], 10).addTo(circles);
    }else{
      
      let splitCoords = null
      event.tags.forEach((item) => {
        if(item[0] === 'a'){
          splitCoords = item[1].split(',')
          map.setView([splitCoords[0], splitCoords[1]], 20)
          map.eachLayer((layer) => {
            if(layer._mRadius === 20){
              map.removeLayer(layer);
            }
          });
          let circle = L.circle([splitCoords[0], splitCoords[1]], 10).addTo(circles);
        }
      });

      let marker = L.marker(
        [splitCoords[0], splitCoords[1]], 
        { icon: osmIcon }
      ).addTo(map).on('click', markerClick);
      marker.locationData = event
    }

    function markerClick(e) {
      router
        .at("location")
        .cx({context: ctx.concat(note)})
        .open()
    }
  }
</script>

{#if ready}
  <div>

    <div border="true" interactive="false" class="relative flex gap-4 rounded p-3 text-lightest bg-swap border-r-4 border-transparent bg-dark">
        
        {#if note.type === "nostr"}

          <div>
              <a class="text-lg font-bold transition-all cursor-pointer">
                  <div class="h-10 w-10 shrink-0 overflow-hidden rounded-full bg-black bg-cover bg-center" style="background-image: url(https://imgproxy.coracle.social/x/s:280:280/aHR0cHM6Ly9pLm5vc3RyLmJ1aWxkL1o3ajguanBn); "></div>
              </a>
          </div>

        {/if}
        
        <div class="flex min-w-0 flex-grow flex-col gap-2">
            <div class="flex flex-col gap-2">
                <div class="flex gap-2"> </div>
                <div class="flex flex-col gap-2 overflow-hidden text-ellipsis">
                  
                  {#if note.type==="nostr"}
                    <h2>{note.content}</h2>
                  {:else}
                    <h2>{note.tags.name}</h2>
                    <ul>
                      {#each Object.entries(note.tags) as [key, value]}
                          <li>{key} : {value}</li>
                      {/each}
                    </ul>
                  {/if}
                </div>
                <div class="cy-note-click-target h-[2px]"></div>

                <button
                  class={cx("relative w-16 pt-1 text-left transition-all hover:pb-1 hover:pt-0 cy-modal-close", null)}
                  on:click={goToLocation}
                  >
                  <img style="width:35px" src="./images/osmPin.svg" />
                </button>

            </div>
            <div class="flex min-w-0 flex-col items-start justify-between sm:flex-row">
                <a class="mr-4 min-w-0 text-lg font-bold transition-all cursor-pointer">
                    {#if note.user}

                      <div class="flex gap-1"><span class="cy-person-name overflow-hidden text-ellipsis">Created by {note.user} via Open Street Maps</span>
                      </div>
                    {:else}
                      <div class="flex gap-1"><span class="cy-person-name overflow-hidden text-ellipsis">Created by {note.npub} via Nostr</span>
                            <div class="flex gap-1 font-normal">
                                <div aria-expanded="false">
                                </div>
                                <div class="hidden">
                                    <div><a class="flex items-center gap-1 transition-all cursor-pointer" href="/help/web-of-trust"><i class="fa fa-info-circle"></i>
                                    WoT Score</a></div>
                                </div>
                            </div>
                        </div>
                    {/if}
                    
                </a>
                
                <a class="whitespace-nowrap text-end text-sm text-lightest transition-all cursor-pointer">
                  {#if !isNaN(note.dateCreated) }
                    {note.dateCreated}
                  {:else}
                    {note.timestamp}
                  {/if}
                </a>

              </div>
        </div>
    </div>

    <OSMNoteReply
      parent={event}
      showBorder={true}
      bind:this={reply} 
    />
  </div>
{:else if showLoading}
  <Spinner />
{/if}
